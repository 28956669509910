import { render, staticRenderFns } from "./ShowEntry.vue?vue&type=template&id=28b8bf1e&scoped=true&"
import script from "./ShowEntry.vue?vue&type=script&lang=js&"
export * from "./ShowEntry.vue?vue&type=script&lang=js&"
import style0 from "./ShowEntry.vue?vue&type=style&index=0&id=28b8bf1e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b8bf1e",
  null
  
)

export default component.exports