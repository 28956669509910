<template>    
  <div class="show-list">
    <template v-for="(show, i) in shows" class="show-item">
      <show-entry v-bind="show" :key="`show-${i}`" :reversed="i % 2 === 0" />
    </template>
  </div>
</template>

<script>
import ShowEntry from "./ShowEntry.vue";

export default {
  components: { ShowEntry },
  props: {
    shows: { type: Array, required: true },
  },
};
</script>


<style lang="scss" scoped>
  .show-list{
    display: flex;
    max-width: 1200px;
    margin: auto;
    margin-top: 35px;
    .show-item{
      width:30%;
    }
  }

  @media screen and (max-width: 850px) {
    .show-list{
      flex-direction: column;
      .show-item{
        width:100%;
      }
      padding: 0 20px!important;
    }

  }

  @media screen and (max-width: 1200px) {
    .show-list{
      flex-direction: column;
      .show-item{
        width:60%;
      }

    }

  }

</style>