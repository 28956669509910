<template>
  <div>

<h1 class="page-title">Shows</h1>
<hr class="line-separator">
    <show-list :shows="shows" />

  </div>
</template>

<script>
// import PageOffset from '@/components/layout/PageOffset';
// import TopBanner from '../homepage/TopBanner';
import ShowList from './ShowList';


export default {
  computed: {
    shows() {
      return [
        { 
          id: 1,
          title: "JOHN WHITMORE", 
          date: 'November 21, 2022 • 9PM',
          description: `Coming Soon`,
          live: true,
          soldout:false,
          media: require("@/assets/new/whitmore.png")
        },
        { 
          id: 2,
          title: "ALFONSO", 
          date: 'December 31, 2022 • 6PM',
          description: `Coming Soon`,
          media: require("@/assets/new/alfonso-item.png")
        },
        { 
          id: 3,
          title: "SKYLAR", 
          date: 'December 31, 2022 • 1:30PM',
          description: `Coming Soon`,
          live: false,
          soldout:true,
          media: require("@/assets/new/skylar.png")
        },

      ]
    }
  },
  components: { 
    // PageOffset,
    ShowList
  }
}
</script>

<style scoped>
/* hr{
    max-width: 1200px;
    margin: auto;
    border-color: rgba(255, 255, 255, 0.3);
} */

h1.page-title{
    line-height: 1;
    padding-bottom: 0;
}

  @media screen and (max-width: 850px) {
      h1.page-title{
          font-weight: 500 !important;
          font-size: 65px !important;
          line-height: 1;
          letter-spacing: 2px !important;
          color: #FFFFFF;
          margin-bottom: 10px;
          text-transform: uppercase;
          padding: 0;
          padding-top: 40px;
      }


  }





</style>