<template>
  <div class="justify-space-around py-6 show-entry-item" :class="`column-${reversed ? 'reversed' : 'normal'}`">
    <div class="media" :class="`order-${reversed ? 2 : 1}`">
          <img v-if="media" :src="media" class="border-radius--10"/>
          <div v-else class="placeholder"></div>
          <img v-if="live" :src="require('@/assets/new/live.svg')" class="status-label"/>

    </div>


    <img v-if="soldout" :src="require('@/assets/new/sold-out.svg')"  class="status-label"/>
    <div class="show-info" :class="`order-${reversed ? 1 : 2}`">
      <div class="show-title">{{ title }}</div>
      <div class="show-date">
        {{date}}
      </div>

      <div class="show-description">
        <span v-if="description" v-html="description"></span>
        <span v-else>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. In delectus
          saepe iure aliquam nihil recusandae, asperiores, autem reprehenderit
          ab aperiam dignissimos deleniti quae vero et ut perferendis veniam.
          Ullam vero fuga hic illo quae unde assumenda totam non provident
          suscipit possimus minima facere sed cum commodi corporis, esse
          explicabo placeat?
        </span>
      </div>

      <div class="d-flex flex-row my-4">
        <v-btn outlined small class="mr-3 small-button" :to="moveto">
          {{ (this.authenticated && this.reserved && this.started) ? 'Join' : 'Purchase' }}
        </v-btn>
        <v-btn outlined small class="mr-3 small-button" :to="movetodetails">
          Details
        </v-btn>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import request from "@/plugins/request";
import awsAuth from "@/cognito/aws-auth";

export default {
  props: {
    id: { type: [String, Number], requried: true},
    title: { type: String, required: true },
    date: { type: String, requried: true },
    description: { type: String },
    media: { type: String },
    live: { type: Boolean, default: false },
    soldout: { type: Boolean, default: false },
    more: { type: Boolean, default: false },
    reversed: { type: Boolean, default: false },
  },

  data(){
    return {
      started: false,
      reserved: false,
      moveto: '/tickets',
      movetodetails:'/show/'+ this.id,
      isAdmin: awsAuth.isAdmin()
    }
  },

  computed: {
    ...mapGetters('auth', ['authenticated'])
  },
  methods: {
    updateConcertState(timer, concerts){
      setTimeout(()=> {
        this.started = concerts.data.length != 0;
      }, timer);
    }
  },
  async mounted() {
    if(this.authenticated){
      const concerts = await request({ method: 'GET', url: '/concert/api/v1/Manager/GetConcertList' })
      if(concerts.status === 200){
        request({ method: 'GET', url: '/concert/api/Nats/GetSnapshotByConcertId?concertId=9987' }).then(res => {
          const startTime = new Date(res.data.start);
          const currentTime = new Date();

          const differenceTime = startTime.getTime() - currentTime.getTime();

          if(differenceTime > 900000) {
            this.started = false;
            const timer = differenceTime - 900000;
            this.updateConcertState(timer, concerts);
          }
          else {
            this.started = concerts.data.length != 0;
          }
        });
      }

      if(!this.isAdmin) {
        const reserved = await request({method: 'GET', url: `/show/api/v1/Pod/GetReservedPods`});
        if(reserved.status === 200){
          this.reserved = reserved.data.length != 0;
          this.moveto = this.reserved && this.started ? ('/pod/1') : '/tickets/purchase';
        }
      }
      else {
        this.reserved = true;
        this.moveto = '/pod/1';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.small-button{
  :deep .v-btn__content{
      font-size:14px!important;
    }
}

.status-label{
  width:auto!important;
  position:absolute;
  top:15%;
  left:0;
}
.show-info {
  margin: 0 1rem;
  max-width:100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 0;
  .show-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: 1.5px;
    color: #ec542d;
  }
  .show-date {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: 2.68px;
    text-transform: uppercase;
  }

  .show-description {
    margin-top: 3px;
    font-weight: 200;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 2.7px;
    max-width: 90%;

    font-size: 12px;
    line-height: 1.2;
    display: inline-block;

  }
}
.v-btn:not(.v-btn--round).v-size--x-large {
  min-width: 200px;
}

.my-4{
  margin-top: 7px !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  .mr-3{
    background-color:red;
      .v-btn__content{
          font-size: 10px!important;
          font-weight: 400!important;
      }
  }  

    height: 28px;
    padding:0 15px;
}

.media {
  flex: 1;
  position: relative;
  height: 100%;
  padding-left:0!important;

  img{
    width:100%;
  }

}

.py-6{
  justify-content: center!important;
  max-width:100em;
  margin:auto;
  width: 32%;
  position:relative;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.mobile-media{
  display:none;
}

img{
  max-width:100%;
}
@media screen and(max-width:1200px) {
  h1.page-title{
    padding:0;
  }
  .media{
    // display:none;
  }
  .mobile-media{
    display:block;
    padding:1em 0;
  }
.py-6{
  width:90%;
  margin-bottom: 15px;
}



      .show-info {
          max-width:100%;
          text-align: left;
          .show-title {
            font-size: 30px;
            line-height: 1;
          }
          .show-date {
            font-size: 22px;
            line-height: 50px;
            letter-spacing: 2px;
          }

          .show-description {
            margin-top: 0;
          }

          .mr-3{
            margin-right:0!important;
            margin-top:1em;
            font-size: 11px;
            font-weight: 400;
            padding: 0 15px;
            span.v-btn__content{
              font-size: 10px!important;
              font-weight: 400!important;
            }
          }
      }

  
        main .order-2{
        padding-left:0!important;
      }

}

@media screen and(max-width:850px) {
    h1.page-title{
      padding:0;
    }

    .mobile-media{
      display:block;
      padding:1em 0;
    }
  .show-entry-item{
    width:100%;
    margin-bottom: 15px;
  }

  .status-label{
      position: absolute;
      left: 0;
      bottom: 30px!important;
      top: unset;
  }

  .show-info {
      max-width:100%;
      text-align: center;
      position:relative;
      margin: 0;
      .show-title {
        font-size: 35px;
        line-height: 1.1;
            margin-top: 10px;
      }
      .show-date {
          font-size: 14px;
          line-height: 1.1;
          letter-spacing: 2px;
          margin-bottom: 15px;
      }

      .show-description {
        margin-top: 0;
      }

      .mr-3{

        margin-top:1em;
        font-size: 11px;
        font-weight: 400;
        padding: 0 15px;
        margin-right: 10px!important;
        height: 60px;s
        span.v-btn__content{
          font-size: 10px!important;
          font-weight: 400!important;
        }
      }

        margin-bottom:50px;
  }
      .my-4{
            text-align: center;
            flex-direction: column!important;
      }


    main .order-2{
        padding-left:0!important;
    }
  

}

</style>
